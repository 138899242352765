const mobileBreakpoint = 576 /* px */;

// Function to hide or show the header fold based on screen width
function toggleHeaderFold() {
    const headerFold = document.getElementById('headerFold');
    const headerFoldToggle = document.querySelector('[data-bs-target="#headerFold"]');

    if (window.innerWidth < mobileBreakpoint) {
        headerFold.classList.remove('show');
        headerFoldToggle.classList.add('collapsed');
        headerFoldToggle.setAttribute('aria-expanded', false);
    } else {
        headerFold.classList.add('show');
        headerFoldToggle.classList.remove('collapsed');
        headerFoldToggle.setAttribute('aria-expanded', true);
    }
}

// Initial check on page load
toggleHeaderFold();

// Add event listener for the 'resize' event
window.addEventListener('resize', toggleHeaderFold);
