/**
 * Toggles the color scheme between 'light' and 'dark'.
 */
function toggleTheme() {
    if (html.dataset.bsTheme === 'light') {
        html.dataset.bsTheme = 'dark';
    } else {
        html.dataset.bsTheme = 'light';
    }

    // Save the theme preference to localStorage
    localStorage.setItem('themePreference', html.dataset.bsTheme);

    // Unblock transitions. They are blocked on initial page load.
    toggleContainer.classList.remove('no-transition');

    updateToggle();
}

/**
 * Updates the toggle button state based on the current theme.
 */
function updateToggle() {
    toggle.checked = html.dataset.bsTheme === 'light';
}

const toggle = document.querySelector('.color-scheme-toggle');
const toggleContainer = document.querySelector('.color-scheme-toggle-container');
const html = document.querySelector('html');

// Check for system preference and set the theme initially
const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)');
const prefersLightScheme = window.matchMedia('(prefers-color-scheme: light)');

// Check for saved preference in localStorage
const savedThemePreference = localStorage.getItem('themePreference');

if (savedThemePreference) {
    html.dataset.bsTheme = savedThemePreference;
} else if (prefersDarkScheme.matches) {
    html.dataset.bsTheme = 'dark';
} else if (prefersLightScheme.matches) {
    html.dataset.bsTheme = 'light';
}

/**
 * Handles initial page load.
 */
function setup() {
    // Block transitions until toggleTheme() is called
    toggleContainer.classList.add('no-transition');

    toggle.addEventListener('click', toggleTheme);

    updateToggle();
}

setup();

// Listen for changes in system preference and update the theme accordingly
prefersDarkScheme.addEventListener('change', (e) => {
    if (e.matches) {
        html.dataset.bsTheme = 'dark';
    } else {
        html.dataset.bsTheme = 'light';
    }

    // Save the theme preference to localStorage
    localStorage.setItem('themePreference', html.dataset.bsTheme);

    // Update the toggle button state
    updateToggle();
});

prefersLightScheme.addEventListener('change', (e) => {
    if (e.matches) {
        html.dataset.bsTheme = 'light';
    } else {
        html.dataset.bsTheme = 'dark';
    }

    // Save the theme preference to localStorage
    localStorage.setItem('themePreference', html.dataset.bsTheme);

    // Update the toggle button state
    updateToggle();
});
