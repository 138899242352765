/**
 * @file Polyfill for CSS `text-wrap` property
 * @see Based on {@link https://www.ctrl.blog/entry/text-wrap-balance.html | "Improving the *New York Times*’ line wrap balancer"}
 *
 * @license
 *
 * © 2023 Jackson Willis <https://www.jacksonwillis.us/>
 * SPDX-License-Identifier: Apache-2.0
 *
 * © 2021 Daniel Aleksandersen <https://www.daniel.priv.no/>
 * SPDX-License-Identifier: Apache-2.0
 *
 * © 2016–2017 The New York Times Company <https://www.nytco.com/>
 * SPDX-License-Identifier: Apache-2.0
 */

(function () {
  'use strict';

  // initializes recursive binary search

  /**
   * Balances the text of the given element by making it as narrow as possible while maintaining its current height (number of lines).
   * This is a polyfill for the CSS `text-wrap` property.
   *
   * @param element The element to balance. Must be a block-level element.
   */
  function balanceText(element: HTMLElement) {
    if (textElementIsMultipleLines(element)) {
      element.style.maxWidth = 'initial';

      const width = element.parentElement ? element.parentElement.clientWidth : 0;
      const bottomRange = Math.max(100, Math.floor(width / 2));

      squeezeContainer(element, element.clientHeight, bottomRange, width);
    }
    revealElement(element);
  }

  // Make the headline element as narrow as possible while maintaining its current height (number of lines). Binary search.
  function squeezeContainer(
    headline: HTMLElement,
    originalHeight: number,
    bottomRange: number,
    topRange: number
  ): void {
    if ((bottomRange + 4) >= topRange) {
      headline.style.maxWidth = Math.ceil(topRange) + 'px';
      return;
    }

    const mid = (bottomRange + topRange) / 2;

    headline.style.maxWidth = mid + 'px';

    if (headline.clientHeight > originalHeight) {
      // we've squoze too far and headline has spilled onto an additional line; recurse on wider range
      squeezeContainer(headline, originalHeight, mid, topRange);
    }
    else {
      // headline has not wrapped to another line; keep squeezing!
      squeezeContainer(headline, originalHeight, bottomRange, mid);
    }
  }

  // check if element text spans multiple lines
  function textElementIsMultipleLines(element: Element): boolean {
    const elementStyles = window.getComputedStyle(element);
    const elementLineHeight = parseInt(elementStyles['line-height'], 10);
    const elementHeight = parseInt(elementStyles['height'], 10);

    return elementLineHeight < elementHeight;
  }

  function initialize(): void {
    // future-proofing: the browser natively supports text balancing
    if (CSS.supports('text-wrap', 'balance')) {
      return;
    }

    var candidates = document.querySelectorAll('.balance-text');

    if (window.ResizeObserver) {
      observe(candidates as NodeListOf<HTMLElement>);
    }
    else {
      // just balance the text once, on page load, since we can't watch for resizing
      candidates.forEach((element) => {
        balanceText(element as HTMLElement);
      });

    }
  }

  initialize();

  // Create a ResizeObserver to balance text when the page is resized
  function observe(candidates: NodeListOf<HTMLElement>): void {
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        balanceEntry(entry);
      });
    });

    Array.from(candidates)
      .map((candidate) => candidate.parentElement)
      .forEach((parent) => {
        observer.observe(parent);
      });
  }

  function balanceEntry(entry: ResizeObserverEntry): void {
    const selectorsToBalance = '.balance-text, .balanced-text';
    const unbalancedElements = entry.target.querySelectorAll(selectorsToBalance) as NodeListOf<HTMLElement>;

    unbalancedElements.forEach((element) => {
      balanceText(element);
    });
  }

  function revealElement(element: HTMLElement): void {
    element.classList.remove('balance-text');
    element.classList.add('balanced-text');
  }

  // timer-based fallback if text doesn’t appear after three seconds
  function revealAllText(): void {
    const unbalancedElements = document.querySelectorAll('.balance-text') as NodeListOf<HTMLElement>;

    unbalancedElements.forEach((element) => {
      revealElement(element);
    });
  }

  setTimeout(revealAllText, 3000);

})();
